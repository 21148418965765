import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet sense peu en forma de copa i de color molt ataronjat passant a color més clar groguenc i pla amb els marges molt ondulats. Pot mesurar de 3 a 6 cm de diàmetre. Les espores són el·líptiques i cobertes per una xarxa externa molt vistosa, de 15-20 x 9-10 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      